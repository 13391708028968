<template>
    <v-form @submit="submit" lazy-validation v-model="isFormValidLocal">
        <!-- Mobile -->
        <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-col class="py-0" cols="12" xs="12">
                <cp-text-field   
                    autofocus
                    ref="name_field"
                    :label="$t('forms.ProfileCustomer.fullName.label')"
                    :placeholder="$t('forms.ProfileCustomer.fullName.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>

            <v-col class="py-0" cols="12" xs="8">
                <cp-document-field
                    ref="document_field"
                    :label="$t(`forms.ProfileCustomer.document.label.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    :placeholder="$t(`forms.ProfileCustomer.document.placeholder.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    required type="text"
                    :useCNPJ.sync="computedUseCNPJ"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col class="py-0" cols="12" xs="12">
                <cp-text-field
                    ref="email_field"
                    :label="$t('forms.ProfileCustomer.email.label')"
                    :placeholder="$t('forms.ProfileCustomer.email.placeholder')"
                    :hint="!isEmailLogin ? $t('forms.ProfileCustomer.email.hint') : ''"
                    persistent-hint
                    required type="text"
                    :error-messages="getEmailErrorMessages()"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="!isEmailLogin"
                />
            </v-col>

            <v-row v-if="$vuetify.breakpoint.mdAndUp">
                <v-col class="py-0" cols="12" xs="12">
                    <cp-phone-field
                        ref="phone_field"
                        :label="$t('forms.ProfileCustomer.phone.label')"
                        :placeholder="$t('forms.ProfileCustomer.phone.placeholder')"
                        required type="text"
                        :error-messages="getPhoneErrorMessages()"
                        v-model="value.phone"
                        @input="clearErrorMessages('phone')"
                        @keyup.enter="$emit('triggerSubmit')"
                    />
                </v-col>

                <v-col class="py-0" cols="12" xs="12">
                    <cp-date-field
                        ref="birthdate_field"
                        :label="$t('forms.ProfileCustomer.birthdate.label')"
                        :placeholder="$t('forms.ProfileCustomer.birthdate.placeholder')"
                        required type="text"
                        :error-messages="getErrorMessages('birthdate')"
                        v-model="value.birthdate"
                        @input="clearErrorMessages('birthdate')"
                        @keyup.enter="submit"
                        reactive birthday
                        outputFormat="DD-MM-YYYY"
                    />
                </v-col>
            </v-row>

            <v-row v-else class="mx-0">
                <v-col class="py-0" cols="12" xs="12">
                    <cp-date-field
                        ref="birthdate_field"
                        :label="$t('forms.ProfileCustomer.birthdate.label')"
                        :placeholder="$t('forms.ProfileCustomer.birthdate.placeholder')"
                        required type="text"
                        :error-messages="getErrorMessages('birthdate')"
                        v-model="value.birthdate"
                        @input="clearErrorMessages('birthdate')"
                        @keyup.enter="submit"
                        reactive birthday
                        outputFormat="DD-MM-YYYY"
                    />
                </v-col>

                <v-col v-if="shouldShowField('phone')" class="py-0" cols="12" xs="12">
                    <cp-phone-field
                        ref="phone_field"
                        :label="$t('forms.ProfileCustomer.phone.label')"
                        :placeholder="$t('forms.ProfileCustomer.phone.placeholder')"
                        required type="text"
                        :error-messages="getPhoneErrorMessages()"
                        v-model="value.phone"
                        @input="clearErrorMessages('phone')"
                        @keyup.enter="$emit('triggerSubmit')"
                    />
                </v-col>
            </v-row>

            <v-col v-if="shouldShowField('sex')" class="py-0" cols="12">
                <GenderSelectInput
                    ref="sex_field"
                    :label="$t('forms.ProfileCustomer.gender.label')"
                    :placeholder="$t('forms.ProfileCustomer.gender.placeholder')"
                    :hint="$t('forms.ProfileCustomer.gender.hint')"
                    v-model="value.sex"
                    :error-messages="getErrorMessages('sex_field')"
                    @input="clearErrorMessages('sex_field')"
                />
            </v-col>

            <AddressFieldset v-model="value" />
        </v-row>
        
        <!-- Desktop -->
        <v-row v-else>
            <v-col class="py-0" cols="12" xs="12" sm="6">
                <cp-text-field   
                    autofocus
                    ref="name_field"
                    :label="$t('forms.ProfileCustomer.fullName.label')"
                    :placeholder="$t('forms.ProfileCustomer.fullName.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>

            <v-col class="py-0" cols="12" xs="12" sm="6">
                <cp-text-field
                    ref="email_field"
                    :label="$t('forms.ProfileCustomer.email.label')"
                    :placeholder="$t('forms.ProfileCustomer.email.placeholder')"
                    :hint="!isEmailLogin ? $t('forms.ProfileCustomer.email.hint') : ''"
                    persistent-hint
                    required type="text"
                    :error-messages="getEmailErrorMessages()"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="!isEmailLogin"
                />
            </v-col>


            <v-col class="py-0" cols="12" xs="8" :sm="colSizeRequestDesktopField()">
                <cp-document-field
                    ref="document_field"
                    :label="$t(`forms.ProfileCustomer.document.label.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    :placeholder="$t(`forms.ProfileCustomer.document.placeholder.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    required type="text"
                    :useCNPJ.sync="computedUseCNPJ"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col class="py-0" cols="12" xs="12" :sm="colSizeRequestDesktopField()">
                <cp-date-field
                    ref="birthdate_field"
                    :label="$t('forms.ProfileCustomer.birthdate.label')"
                    :placeholder="$t('forms.ProfileCustomer.birthdate.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('birthdate')"
                    v-model="value.birthdate"
                    @input="clearErrorMessages('birthdate')"
                    @keyup.enter="submit"
                    reactive birthday
                    outputFormat="DD-MM-YYYY"
                />
            </v-col>
            
            <v-col v-if="shouldShowField('phone')" 
                class="py-0" cols="12" xs="12" 
                :sm="colSizeNonRequestDesktopField()"
            >
                <cp-phone-field
                    ref="phone_field"
                    :label="$t('forms.ProfileCustomer.phone.label')"
                    :placeholder="$t('forms.ProfileCustomer.phone.placeholder')"
                    required type="text"
                    :error-messages="getPhoneErrorMessages()"
                    v-model="value.phone"
                    @input="clearErrorMessages('phone')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col v-if="shouldShowField('sex')" 
                class="py-0" cols="12" xs="8" 
                :sm="colSizeNonRequestDesktopField()" 
            >
                <GenderSelectInput
                    ref="sex_field"
                    :label="$t('forms.ProfileCustomer.gender.label')"
                    :placeholder="$t('forms.ProfileCustomer.gender.placeholder')"
                    :hint="$t('forms.ProfileCustomer.gender.hint')"
                    v-model="value.sex"
                    :error-messages="getErrorMessages('sex_field')"
                    @input="clearErrorMessages('sex_field')"
                />
            </v-col>

            <AddressFieldset v-model="value" />
        </v-row>
    </v-form>
</template>

<script>
import FormMixin         from '@/mixins/FormMixin'
import SimpleCustomer    from '@/models/SimpleCustomer'
import CompanySettings   from '@/models/CompanySettings'
import GenderSelectInput from '@/components/inputs/GenderSelectInput'
import AddressFieldset   from '@/components/forms/fieldset/address/AddressFieldset'
import { mapGetters }    from 'vuex'

export default {
    name: 'ProfileForm',
    components: { GenderSelectInput, AddressFieldset },
    mixins: [ FormMixin(SimpleCustomer, 'name_field') ],
    data: () => ({
        company: new CompanySettings
    }),
    props: {
        useCNPJ: Boolean
    },
    methods: {
        // Caso o usuário tenha login por email, impede que o mesmo deixa de informar um email
        getEmailErrorMessages() {
            let emailField = this.$refs.email_field

            if (this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') && emailField && !emailField.localValue) 
                return [this.$t('forms.ProfileCustomer.email.required')].concat(this.getErrorMessages('email'))

            return this.getErrorMessages('email')
        },
        // Caso o usuário tenha login por phone, impede que o mesmo deixa de informar um número
        getPhoneErrorMessages() {
            let phoneField = this.$refs.phone_field

            if (this.value.login_type == 'PHONE' && phoneField && (!phoneField.localValue || !phoneField.localValue.replace(/\D/g,'')))
                return [this.$t('forms.ProfileCustomer.phone.required')].concat(this.getErrorMessages('phone'))

            return this.getErrorMessages('phone')
        },
        // Confere se a senha do input é igual a sua confimação e, caso não seja, atribuí este erro ao array de erros
        getConfirmationPasswordErrorMessages() {
            let newPasswordField = this.$refs.new_password_field
            let passwordConfirmationField = this.$refs.password_confirmation_field

            let mismatchError = []
            if ( newPasswordField && passwordConfirmationField &&
                newPasswordField.localValue != passwordConfirmationField.localValue ) {

                return [this.$t('forms.ProfileCustomer.password_confirmation.mismatch')].concat(this.getErrorMessages('password_confirmation'))
            }
            
            return this.getErrorMessages('password_confirmation')
        },
        getLocalPassword() {
            return this.$refs.new_password_field.computedValue
        },
        shouldShowField(field) {
            return this.$lodash.get(this.companySettings, `customFields.Customer.${field}.active`) === false ? false : true
        },
        colSizeRequestDesktopField() {
            let countRequestedFiels = this.company.countTotalFieldsInUse(
                'Customer',
                this.companySettings.customFields,
                this.requestedFields
            )
            // retorna tamanho da da coluna a ser utilizada pelo vuetify
            return countRequestedFiels.hasAdditionFields ? 6 : 3
        },
        colSizeNonRequestDesktopField() {
            let fieldsNumberInUseIsPair = this.company.fieldsNumberInUseIsPair(
                'Customer',
                this.companySettings.customFields,
                this.requestedFields,
            )
            // retorna tamanho da da coluna a ser utilizada pelo vuetify
            return !fieldsNumberInUseIsPair ? 6 : 3
        }
    },
    computed: {
        ...mapGetters({
            companySettings: 'company/settings',
        }),
        computedUseCNPJ: {
            get() {
                return this.useCNPJ
            },
            set(value) {
                this.$emit('document-changed', value)
            }
        },
        isEmailLogin() {
            return this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
        },
        requestedFields() {
            return ["name", "document", "email", "birthdate"]
        }
    },
}
</script>
