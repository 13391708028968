var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    this.authUser.login_type ==
      _vm.$constants.getConstant("CUSTOMER_LOGIN_TYPE_EMAIL") &&
    !this.authUser.emailVerified &&
    !_vm.localEmailVerified
      ? _c(
          "div",
          {
            staticClass:
              "d-flex align-center justify-space-between card-content",
            attrs: { width: "100%" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center justify-space-between card-content email-confirmation-content"
              },
              [
                _c(
                  "div",
                  { staticClass: "mx-3 d-flex justify-center align-center" },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-3", attrs: { color: "primary" } },
                      [_vm._v("$cp_alert")]
                    ),
                    _c("div", { staticClass: "caption primary--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "views.SettingsView.send_email_confirmation_title"
                            )
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row justify-end mx-3 resend-mail"
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mail-btn custom-btn",
                        class: _vm.isLightTheme
                          ? "indigo accent-4--text"
                          : "primary--text",
                        attrs: {
                          depressed: "",
                          outlined: "",
                          tile: "",
                          loading: _vm.emailLoading
                        },
                        on: { click: _vm.sendEmailConfirmation }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.SettingsView.navigation.send_email_confirmation"
                            )
                          )
                        )
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "mx-2 ml-4" },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { size: "12", color: "primary" },
                            on: { click: _vm.hideModal }
                          },
                          [_vm._v("fas fa-times")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column align-center justify-center card-content email-confirmation-content-xs py-2"
              },
              [
                _c(
                  "div",
                  { staticClass: "mx-3 d-flex justify-center align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-n8 mr-2",
                        attrs: { color: "primary" }
                      },
                      [_vm._v("$cp_alert")]
                    ),
                    _c("div", { staticClass: "caption primary--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "views.SettingsView.send_email_confirmation_title"
                            )
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row justify-end resend-mail mt-2"
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mail-btn custom-btn",
                        class: _vm.isLightTheme
                          ? "indigo accent-4--text"
                          : "primary--text",
                        attrs: {
                          depressed: "",
                          outlined: "",
                          tile: "",
                          loading: _vm.emailLoading
                        },
                        on: { click: _vm.sendEmailConfirmation }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.SettingsView.navigation.send_email_confirmation"
                            )
                          )
                        )
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "mx-2 ml-4" },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { size: "12", color: "primary" },
                            on: { click: _vm.hideModal }
                          },
                          [_vm._v("fas fa-times")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }