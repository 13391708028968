var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      title: _vm.$t("modals.CodeModal.title"),
      subtitle: _vm.$t("modals.CodeModal.subtitle"),
      confirm: _vm.$t("modals.CodeModal.confirm"),
      cancel: _vm.$t("modals.CodeModal.cancel"),
      "max-width": "400px",
      customConfirm: true,
      customCancel: true,
      persistent: true,
      confirmDisabled: !_vm.isValid
    },
    on: { confirm: _vm.handleConfirm, cancel: _vm.handleCancel },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [
            _c(
              "v-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.handleConfirm($event)
                  }
                },
                model: {
                  value: _vm.isValid,
                  callback: function($$v) {
                    _vm.isValid = $$v
                  },
                  expression: "isValid"
                }
              },
              [
                _c("v-text-field", {
                  staticClass: "pt-6",
                  attrs: {
                    label: "Código",
                    solo: "",
                    flat: "",
                    rules: _vm.rules.code
                  },
                  model: {
                    value: _vm.code,
                    callback: function($$v) {
                      _vm.code = $$v
                    },
                    expression: "code"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }