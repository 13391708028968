var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      staticClass: "d-flex justify-end",
      attrs: {
        persistent: "",
        hasConfirm: true,
        confirmDisabled: _vm.confirmDisabled,
        hasCancel: true,
        title: _vm.title,
        hasSubtitle: false,
        isForm: true,
        hasActions: true,
        confirm: _vm.confirm,
        cancel: _vm.cancel,
        "max-width": "600px",
        confirmColor: "primary",
        cancelColor: "background"
      },
      on: { confirm: _vm.handleConfirm, closed: _vm.closed },
      scopedSlots: _vm._u([
        {
          key: "text",
          fn: function() {
            return [
              _c("ResetPassowrdForm", {
                ref: "reset_password_form",
                staticClass: "text-start",
                on: {
                  "password-error": _vm.passwordError,
                  "set-users-front": _vm.setUsersFront,
                  "close-dialog": _vm.closeDilago,
                  "handle-logout": _vm.handlerConfirmLogout,
                  "confirm-disabled": _vm.confirmEnable
                },
                model: {
                  value: _vm.userCredentials,
                  callback: function($$v) {
                    _vm.userCredentials = $$v
                  },
                  expression: "userCredentials"
                }
              })
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c("div", { staticClass: "elevation-0 text-start" }, [
        _c("span", { staticClass: "primary--text" }, [
          _vm._v(_vm._s(_vm.$t("forms.ResetPassword.title")))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }