import _     from 'lodash'
import axios from 'axios'

export const findZipCodeLink = 'https://buscacepinter.correios.com.br/app/endereco/'

/**
 * Retorna as informações Código Postal pesquisado
 *
 * @param {string} postal_code Código Postal sem pontos e dígitos
 */
export async function getZipCodeWebService(postal_code) {
    // [TODO] FID-1484 generalizar e implementar interface,
    // doc: https://viacep.com.br/
    let externalWebService = `https://viacep.com.br/ws/${postal_code}/json/`

    if (!postal_code) {
        throw new TypeError('postal_code_missing')
    }

    let response = await axios.get(externalWebService)
        .catch(err => {
            throw new Error('invalid_format')
        })

    if (response && response.data && response.data.erro) {
        throw new Error('cep_not_exists')
    }

    // Faz o parse da resposta para os nossos campos esperados
    // tratar parse de outros endpoints [FID-1484]
    return {
        address_state   : _.get(response, 'data.uf'),
        address_district: _.get(response, 'data.bairro'),
        address_city    : _.get(response, 'data.localidade'),
        address_street  : _.get(response, 'data.logradouro'),
    }
}
