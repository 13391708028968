<template>
   <BaseModal
        ref="modal"
        v-model="isVisible"
        persistent
        :hasConfirm="true"
        :confirmDisabled="confirmDisabled"
        :hasCancel="true"
        :title="title" :hasSubtitle="false"
        :isForm="true"
        :hasActions="true"
        :confirm="confirm"
        :cancel="cancel"
        max-width="600px"
        @confirm="handleConfirm" @closed="closed"
        confirmColor="primary" cancelColor="background"
        class="d-flex justify-end"
    > 
    <div class="elevation-0 text-start">
        <span class="primary--text">{{ $t('forms.ResetPassword.title') }}</span>
    </div>

        <template #text>
            <ResetPassowrdForm
                ref="reset_password_form"
                class="text-start"
                v-model="userCredentials"
                @password-error="passwordError"
                @set-users-front="setUsersFront"
                @close-dialog="closeDilago"
                @handle-logout="handlerConfirmLogout"
                @confirm-disabled="confirmEnable"
            />
        </template>
    </BaseModal>
</template>

<script>
import ResetPassowrdForm from '@/components/forms/ResetPasswordForm'
import RateColor         from '@/shared/mixins/RateColor'
import BaseModal         from '@/components/modals/BaseModal'
import { mapGetters }    from 'vuex'


export default {
    name: 'ResetPasswordModal',
    mixins: [ RateColor ],
    components: { ResetPassowrdForm, BaseModal },
    data: vm => ({
        isVisible: false,
        title: vm.$t('forms.ResetPassword.title'),
        confirm: vm.$t('forms.ResetPassword.update'),
        cancel: vm.$t('forms.ResetPassword.cancel'),
        userCredentials: null,
        confirmDisabled: true,
    }),
    created() {
        this.userCredentials = this.authUser.clone()
    },
    methods: {
         open() {
            this.$emit('opening')
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
        closed() {
            this.$refs.reset_password_form.resetForm()
            this.$emit('closed')
        },
        closeDilago() {
            this.$refs.modal.close()
        },
        handleConfirm() {
            this.$emit('confirm', this.userCredentials)
            this.close()
        },
        passwordError() {
            this.$emit('password-error')
        },
        setUsersFront() {
            this.$emit('set-users-front')
        },
        async handlerConfirmLogout(){
            this.$emit('handle-logout')
        },
        confirmEnable(v) {
            this.confirmDisabled = v
        }

    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
        }),
    }
}
</script>

<style>

</style>