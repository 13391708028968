var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "password_form",
      on: { submit: _vm.submit },
      model: {
        value: _vm.isResetFormValidLocal,
        callback: function($$v) {
          _vm.isResetFormValidLocal = $$v
        },
        expression: "isResetFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("cp-password-field", {
                ref: "current_password_field",
                attrs: {
                  label: _vm.$t("forms.ResetPassword.current_password.label"),
                  hint: _vm.$t("forms.ResetPassword.current_password.hint"),
                  placeholder: _vm.$t(
                    "forms.ResetPassword.current_password.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("current_password"),
                  required: "",
                  rules: _vm.rules.password
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("current_password")
                  }
                },
                model: {
                  value: _vm.value.current_password,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "current_password", $$v)
                  },
                  expression: "value.current_password"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("cp-password-field", {
                ref: "password_field",
                attrs: {
                  label: _vm.$t("forms.ResetPassword.password.label"),
                  hint: _vm.$t("forms.ResetPassword.password.hint"),
                  placeholder: _vm.$t(
                    "forms.ResetPassword.password.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("password"),
                  rules: _vm.rules.password
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("password")
                  }
                },
                model: {
                  value: _vm.value.password,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "password", $$v)
                  },
                  expression: "value.password"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("cp-password-field", {
                ref: "password_confirmation_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ResetPassword.password_confirmation.label"
                  ),
                  hint: _vm.$t(
                    "forms.ResetPassword.password_confirmation.hint"
                  ),
                  placeholder: _vm.$t(
                    "forms.ResetPassword.password_confirmation.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getConfirmationPasswordErrorMessages(),
                  rules: _vm.rules.password
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("password_confirmation")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.confirm($event)
                  }
                },
                model: {
                  value: _vm.value.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "password_confirmation", $$v)
                  },
                  expression: "value.password_confirmation"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-col", { staticClass: "pa-0 d-flex align-center" }, [
            _c(
              "div",
              {
                staticClass: "primary--text body-2 clickable",
                on: { click: _vm.handlerConfirmLogout }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("forms.ResetPassword.forgot_password")) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }