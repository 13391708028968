<template>
    <v-form ref="password_form" @submit="submit" v-model="isResetFormValidLocal">
        <v-row class="mx-0">
            <v-col class="pa-0" cols="12">
                <cp-password-field
                    ref="current_password_field"
                    :label="$t('forms.ResetPassword.current_password.label')"
                    :hint="$t('forms.ResetPassword.current_password.hint')"
                    :placeholder="$t('forms.ResetPassword.current_password.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('current_password')"
                    v-model="value.current_password"
                    @input="clearErrorMessages('current_password')"
                    required
                    :rules="rules.password"
                />
            </v-col>

            <v-col class="pa-0" cols="12">
                <cp-password-field
                    ref="password_field"
                    :label="$t('forms.ResetPassword.password.label')"
                    :hint="$t('forms.ResetPassword.password.hint')"
                    :placeholder="$t('forms.ResetPassword.password.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('password')"
                    v-model="value.password"
                    @input="clearErrorMessages('password')"
                    :rules="rules.password"
                />
            </v-col>

            <v-col class="pa-0" cols="12">
                <cp-password-field
                    ref="password_confirmation_field"
                    :label="$t('forms.ResetPassword.password_confirmation.label')"
                    :hint="$t('forms.ResetPassword.password_confirmation.hint')"
                    :placeholder="$t('forms.ResetPassword.password_confirmation.placeholder')"
                    type="text"
                    :error-messages="getConfirmationPasswordErrorMessages()"
                    v-model="value.password_confirmation"
                    @input="clearErrorMessages('password_confirmation')"
                    :rules="rules.password"
                    @keyup.enter="confirm"
                />
            </v-col>
        </v-row>

        <v-row class="mx-0">
            <v-col class="pa-0 d-flex align-center">  
                <div class="primary--text body-2 clickable" @click="handlerConfirmLogout">
                    {{ $t('forms.ResetPassword.forgot_password') }}
                </div>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import FormMixin            from '@/mixins/FormMixin'
import SimpleCustomer       from '@/models/SimpleCustomer'
import { required, length } from '@/utils/validation'

export default {
    name: 'ResetPasswordForm',
    data: vm => ({
        rules: {
            password: [required.and(length(6, 30))],
        },
        isResetFormValidLocal: false,
    }),
    mixins: [ FormMixin(SimpleCustomer, 'name_field') ],
    methods: {
        // Confere se a senha do input é igual a sua confimação e, caso não seja, atribuí este erro ao array de erros
        getConfirmationPasswordErrorMessages() {
            let passwordField = this.$refs.password_field
            let passwordConfirmationField = this.$refs.password_confirmation_field

            if ( passwordField && passwordConfirmationField &&
                 passwordField.localValue != passwordConfirmationField.localValue ) {

                return [this.$t('forms.ResetPassword.password_confirmation.mismatch')].concat(this.getErrorMessages('password_confirmation'))
            }

            this.$emit('confirm-disabled', !this.isResetFormValidLocal)
            return this.getErrorMessages('password_confirmation')
        },
        async handlerConfirmLogout(){
            this.$emit('handle-logout')
        },
        confirm() {
            if (!this.isResetFormValidLocal) return

            this.$emit('confirm')
        },
        resetForm() {
            this.$refs['password_form'].reset()
        }
    },
}
</script>

<style>

</style>