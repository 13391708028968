var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LoadingModal", { ref: "loading" }),
      _c("CodeModal", { ref: "code", on: { received: _vm.handleReceived } }),
      _c("ConfirmationModal", {
        ref: "confirmation",
        attrs: { hasText: true, hasSubtitle: false },
        on: { confirm: _vm.pushForgotPassword }
      }),
      _c("ResetPasswordModal", {
        ref: "reset_password",
        on: {
          confirm: _vm.submitResetPassword,
          "password-error": _vm.passwordError,
          "set-users-front": _vm.setUsersFront,
          "handle-logout": _vm.handlerForgotPassword
        }
      }),
      _c(
        "v-container",
        { staticClass: "pb-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-breadcrumbs",
                {
                  staticClass: "pb-1 px-3",
                  class: _vm.isLightTheme ? "breadcrumb-item" : "",
                  attrs: { items: _vm.breadcrumbs }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "breadcrumbs-chevro",
                      attrs: { slot: "divider" },
                      slot: "divider"
                    },
                    [_vm._v("fas fa-chevron-right")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "6", sm: "12" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "section-title font-weight-bold primary_font--text"
                    },
                    [_vm._v(_vm._s(_vm.$t("views.SettingsView.title")))]
                  )
                ]
              ),
              !_vm.authUser.emailVerified
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "6", sm: "12" } },
                    [
                      _vm.showEmailConfirmation
                        ? _c("EmailConfirmation", {
                            on: {
                              hide: function($event) {
                                _vm.showEmailConfirmation = false
                              },
                              "email-sent": _vm.emailSent,
                              "email-error": _vm.emailError
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pt-1" },
        [
          !_vm.loading
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-0", attrs: { outlined: "" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "primary--text subtitle-1" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "views.SettingsView.user_customer.title"
                                  )
                                )
                              )
                            ]
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("ProfileForm", {
                                ref: "credentials_form",
                                attrs: {
                                  isFormValid: _vm.isCredentialsFormValid,
                                  useCNPJ: _vm.useCNPJ,
                                  loading: _vm.loading
                                },
                                on: {
                                  "update:isFormValid": function($event) {
                                    _vm.isCredentialsFormValid = $event
                                  },
                                  "update:is-form-valid": function($event) {
                                    _vm.isCredentialsFormValid = $event
                                  },
                                  submit: function($event) {
                                    return _vm.submit(_vm.userCredentials)
                                  },
                                  "document-changed": _vm.changeDocument,
                                  triggerSubmit: _vm.triggerSubmitCredentials
                                },
                                model: {
                                  value: _vm.userCredentials,
                                  callback: function($$v) {
                                    _vm.userCredentials = $$v
                                  },
                                  expression: "userCredentials"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-card-actions",
                                { staticClass: "d-flex justify-end pb-8 px-4" },
                                [
                                  _vm.userCredentials.login_type ==
                                  _vm.$constants.getConstant(
                                    "CUSTOMER_LOGIN_TYPE_EMAIL"
                                  )
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary px-4",
                                          class: _vm.isLightTheme
                                            ? "primary_font--text"
                                            : "",
                                          attrs: { elevation: "0" },
                                          on: { click: _vm.resetPassword }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.ResetPassword.title"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "background px-4",
                                      attrs: {
                                        text: "",
                                        color: "primary__font--text"
                                      },
                                      on: { click: _vm.goHome }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.$t(
                                            "views.SettingsView.navigation.cancel"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-4",
                                      class: _vm.isLightTheme
                                        ? "primary_font--text"
                                        : "",
                                      attrs: {
                                        elevation: "0",
                                        color: "primary",
                                        disabled: !_vm.isCredentialsFormValid,
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: _vm.triggerSubmitCredentials
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.update")))]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-card-text",
                                [
                                  _c("div", { staticClass: "mb-8" }, [
                                    _vm.userCredentials.login_type ==
                                    _vm.$constants.getConstant(
                                      "CUSTOMER_LOGIN_TYPE_EMAIL"
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "primary--text clickable",
                                            on: { click: _vm.resetPassword }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.ResetPassword.title"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-2",
                                      class: _vm.isLightTheme
                                        ? "primary_font--text"
                                        : "",
                                      attrs: {
                                        block: "",
                                        color: "primary",
                                        elevation: "0",
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: _vm.triggerSubmitCredentials
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.update")))]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        text: "",
                                        color: "blue"
                                      },
                                      on: { click: _vm.goHome }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.$t(
                                            "views.SettingsView.navigation.cancel"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("div", { staticClass: "text-center mt-5" }, [
                _vm._v(" " + _vm._s(_vm.$t("globals.loading")) + " ")
              ]),
          _c("div", { attrs: { id: "recaptcha" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }