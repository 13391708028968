var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: { submit: _vm.submit },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xs: "12" } },
                [
                  _c("cp-text-field", {
                    ref: "name_field",
                    attrs: {
                      autofocus: "",
                      label: _vm.$t("forms.ProfileCustomer.fullName.label"),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.fullName.placeholder"
                      ),
                      required: "",
                      type: "text",
                      "error-messages": _vm.getErrorMessages("name")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("name")
                      }
                    },
                    model: {
                      value: _vm.value.name,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "name", $$v)
                      },
                      expression: "value.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xs: "8" } },
                [
                  _c("cp-document-field", {
                    ref: "document_field",
                    attrs: {
                      label: _vm.$t(
                        "forms.ProfileCustomer.document.label." +
                          (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                      ),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.document.placeholder." +
                          (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                      ),
                      required: "",
                      type: "text",
                      useCNPJ: _vm.computedUseCNPJ,
                      "error-messages": _vm.getErrorMessages("document")
                    },
                    on: {
                      "update:useCNPJ": function($event) {
                        _vm.computedUseCNPJ = $event
                      },
                      "update:use-c-n-p-j": function($event) {
                        _vm.computedUseCNPJ = $event
                      },
                      input: function($event) {
                        return _vm.clearErrorMessages("document")
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.$emit("triggerSubmit")
                      }
                    },
                    model: {
                      value: _vm.value.document,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "document", $$v)
                      },
                      expression: "value.document"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12", xs: "12" } },
                [
                  _c("cp-text-field", {
                    ref: "email_field",
                    attrs: {
                      label: _vm.$t("forms.ProfileCustomer.email.label"),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.email.placeholder"
                      ),
                      hint: !_vm.isEmailLogin
                        ? _vm.$t("forms.ProfileCustomer.email.hint")
                        : "",
                      "persistent-hint": "",
                      required: "",
                      type: "text",
                      "error-messages": _vm.getEmailErrorMessages(),
                      disabled: !_vm.isEmailLogin
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("email")
                      }
                    },
                    model: {
                      value: _vm.value.email,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "email", $$v)
                      },
                      expression: "value.email"
                    }
                  })
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", xs: "12" }
                        },
                        [
                          _c("cp-phone-field", {
                            ref: "phone_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.ProfileCustomer.phone.label"
                              ),
                              placeholder: _vm.$t(
                                "forms.ProfileCustomer.phone.placeholder"
                              ),
                              required: "",
                              type: "text",
                              "error-messages": _vm.getPhoneErrorMessages()
                            },
                            on: {
                              input: function($event) {
                                return _vm.clearErrorMessages("phone")
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.$emit("triggerSubmit")
                              }
                            },
                            model: {
                              value: _vm.value.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "phone", $$v)
                              },
                              expression: "value.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", xs: "12" }
                        },
                        [
                          _c("cp-date-field", {
                            ref: "birthdate_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.ProfileCustomer.birthdate.label"
                              ),
                              placeholder: _vm.$t(
                                "forms.ProfileCustomer.birthdate.placeholder"
                              ),
                              required: "",
                              type: "text",
                              "error-messages": _vm.getErrorMessages(
                                "birthdate"
                              ),
                              reactive: "",
                              birthday: "",
                              outputFormat: "DD-MM-YYYY"
                            },
                            on: {
                              input: function($event) {
                                return _vm.clearErrorMessages("birthdate")
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.submit($event)
                              }
                            },
                            model: {
                              value: _vm.value.birthdate,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "birthdate", $$v)
                              },
                              expression: "value.birthdate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", xs: "12" }
                        },
                        [
                          _c("cp-date-field", {
                            ref: "birthdate_field",
                            attrs: {
                              label: _vm.$t(
                                "forms.ProfileCustomer.birthdate.label"
                              ),
                              placeholder: _vm.$t(
                                "forms.ProfileCustomer.birthdate.placeholder"
                              ),
                              required: "",
                              type: "text",
                              "error-messages": _vm.getErrorMessages(
                                "birthdate"
                              ),
                              reactive: "",
                              birthday: "",
                              outputFormat: "DD-MM-YYYY"
                            },
                            on: {
                              input: function($event) {
                                return _vm.clearErrorMessages("birthdate")
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.submit($event)
                              }
                            },
                            model: {
                              value: _vm.value.birthdate,
                              callback: function($$v) {
                                _vm.$set(_vm.value, "birthdate", $$v)
                              },
                              expression: "value.birthdate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.shouldShowField("phone")
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", xs: "12" }
                            },
                            [
                              _c("cp-phone-field", {
                                ref: "phone_field",
                                attrs: {
                                  label: _vm.$t(
                                    "forms.ProfileCustomer.phone.label"
                                  ),
                                  placeholder: _vm.$t(
                                    "forms.ProfileCustomer.phone.placeholder"
                                  ),
                                  required: "",
                                  type: "text",
                                  "error-messages": _vm.getPhoneErrorMessages()
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.clearErrorMessages("phone")
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.$emit("triggerSubmit")
                                  }
                                },
                                model: {
                                  value: _vm.value.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.value, "phone", $$v)
                                  },
                                  expression: "value.phone"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
              _vm.shouldShowField("sex")
                ? _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("GenderSelectInput", {
                        ref: "sex_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileCustomer.gender.label"),
                          placeholder: _vm.$t(
                            "forms.ProfileCustomer.gender.placeholder"
                          ),
                          hint: _vm.$t("forms.ProfileCustomer.gender.hint"),
                          "error-messages": _vm.getErrorMessages("sex_field")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("sex_field")
                          }
                        },
                        model: {
                          value: _vm.value.sex,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "sex", $$v)
                          },
                          expression: "value.sex"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("AddressFieldset", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xs: "12", sm: "6" }
                },
                [
                  _c("cp-text-field", {
                    ref: "name_field",
                    attrs: {
                      autofocus: "",
                      label: _vm.$t("forms.ProfileCustomer.fullName.label"),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.fullName.placeholder"
                      ),
                      required: "",
                      type: "text",
                      "error-messages": _vm.getErrorMessages("name")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("name")
                      }
                    },
                    model: {
                      value: _vm.value.name,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "name", $$v)
                      },
                      expression: "value.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xs: "12", sm: "6" }
                },
                [
                  _c("cp-text-field", {
                    ref: "email_field",
                    attrs: {
                      label: _vm.$t("forms.ProfileCustomer.email.label"),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.email.placeholder"
                      ),
                      hint: !_vm.isEmailLogin
                        ? _vm.$t("forms.ProfileCustomer.email.hint")
                        : "",
                      "persistent-hint": "",
                      required: "",
                      type: "text",
                      "error-messages": _vm.getEmailErrorMessages(),
                      disabled: !_vm.isEmailLogin
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("email")
                      }
                    },
                    model: {
                      value: _vm.value.email,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "email", $$v)
                      },
                      expression: "value.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: {
                    cols: "12",
                    xs: "8",
                    sm: _vm.colSizeRequestDesktopField()
                  }
                },
                [
                  _c("cp-document-field", {
                    ref: "document_field",
                    attrs: {
                      label: _vm.$t(
                        "forms.ProfileCustomer.document.label." +
                          (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                      ),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.document.placeholder." +
                          (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                      ),
                      required: "",
                      type: "text",
                      useCNPJ: _vm.computedUseCNPJ,
                      "error-messages": _vm.getErrorMessages("document")
                    },
                    on: {
                      "update:useCNPJ": function($event) {
                        _vm.computedUseCNPJ = $event
                      },
                      "update:use-c-n-p-j": function($event) {
                        _vm.computedUseCNPJ = $event
                      },
                      input: function($event) {
                        return _vm.clearErrorMessages("document")
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.$emit("triggerSubmit")
                      }
                    },
                    model: {
                      value: _vm.value.document,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "document", $$v)
                      },
                      expression: "value.document"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: {
                    cols: "12",
                    xs: "12",
                    sm: _vm.colSizeRequestDesktopField()
                  }
                },
                [
                  _c("cp-date-field", {
                    ref: "birthdate_field",
                    attrs: {
                      label: _vm.$t("forms.ProfileCustomer.birthdate.label"),
                      placeholder: _vm.$t(
                        "forms.ProfileCustomer.birthdate.placeholder"
                      ),
                      required: "",
                      type: "text",
                      "error-messages": _vm.getErrorMessages("birthdate"),
                      reactive: "",
                      birthday: "",
                      outputFormat: "DD-MM-YYYY"
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("birthdate")
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit($event)
                      }
                    },
                    model: {
                      value: _vm.value.birthdate,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "birthdate", $$v)
                      },
                      expression: "value.birthdate"
                    }
                  })
                ],
                1
              ),
              _vm.shouldShowField("phone")
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: _vm.colSizeNonRequestDesktopField()
                      }
                    },
                    [
                      _c("cp-phone-field", {
                        ref: "phone_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileCustomer.phone.label"),
                          placeholder: _vm.$t(
                            "forms.ProfileCustomer.phone.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getPhoneErrorMessages()
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("phone")
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "phone", $$v)
                          },
                          expression: "value.phone"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.shouldShowField("sex")
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: {
                        cols: "12",
                        xs: "8",
                        sm: _vm.colSizeNonRequestDesktopField()
                      }
                    },
                    [
                      _c("GenderSelectInput", {
                        ref: "sex_field",
                        attrs: {
                          label: _vm.$t("forms.ProfileCustomer.gender.label"),
                          placeholder: _vm.$t(
                            "forms.ProfileCustomer.gender.placeholder"
                          ),
                          hint: _vm.$t("forms.ProfileCustomer.gender.hint"),
                          "error-messages": _vm.getErrorMessages("sex_field")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("sex_field")
                          }
                        },
                        model: {
                          value: _vm.value.sex,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "sex", $$v)
                          },
                          expression: "value.sex"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("AddressFieldset", {
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }