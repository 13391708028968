<template>
    <v-row class="mx-0">
        <v-col cols="12" xs="12" sm="12"  md="6" class="py-0">
            <cp-postalcode-field
                ref="postal_code_field"
                :label="$t('fieldset.AddressFieldset.postal_code.label')"
                :placeholder="$t('fieldset.AddressFieldset.postal_code.placeholder')"
                :link_title="$t('fieldset.AddressFieldset.postal_code.link_title')"
                required type="text"
                :error-messages="getErrorMessages('address_postal_code')"
                v-model="value.address_postal_code"
                @input="clearErrorMessages('address_postal_code')"
                :loading="loadPostalCode"
                :search_link="findZipCodeLink"
                :disabled="loadPostalCode"
            />
        </v-col>

         <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
            <cp-text-field
                ref="address_number_field"
                :label="$t('fieldset.AddressFieldset.number.label')"
                :placeholder="$t('fieldset.AddressFieldset.number.placeholder')"
                required
                :rules="rules.number"
                :error-messages="getErrorMessages('address_number')"
                v-model="value.address_number"
                @input="clearErrorMessages('address_number')"
                :disabled="loadPostalCode"
            />
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
            <cp-text-field
                ref="address_street_field"
                :label="$t('fieldset.AddressFieldset.street.label')"
                :placeholder="$t('fieldset.AddressFieldset.street.placeholder')"
                required type="text"
                :error-messages="getErrorMessages('address_street')"
                v-model="value.address_street"
                @input="clearErrorMessages('address_street')"
                :disabled="loadPostalCode"
            />
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
            <cp-text-field
                ref="address_complement_field"
                :label="$t('fieldset.AddressFieldset.complement.label')"
                :placeholder="$t('fieldset.AddressFieldset.complement.placeholder')"
                type="text"
                :error-messages="getErrorMessages('address_complement')"
                v-model="value.address_complement"
                @input="clearErrorMessages('address_complement')"
                :disabled="loadPostalCode"
            />
        </v-col>

        <v-row class="py-0 mx-0">
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field
                    ref="address_district_field"
                    :label="$t('fieldset.AddressFieldset.district.label')"
                    :placeholder="$t('fieldset.AddressFieldset.district.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('address_district')"
                    v-model="value.address_district"
                    @input="clearErrorMessages('address_district')"
                    :disabled="loadPostalCode"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" class="py-0">
                <cp-text-field
                    ref="address_city_field"
                    :label="$t('fieldset.AddressFieldset.city.label')"
                    :placeholder="$t('fieldset.AddressFieldset.city.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('address_city')"
                    v-model="value.address_city"
                    @input="clearErrorMessages('address_city')"
                    :disabled="loadPostalCode"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" class="py-0">
                <cp-text-field
                    ref="address_state_field"
                    :label="$t('fieldset.AddressFieldset.state.label')"
                    :placeholder="$t('fieldset.AddressFieldset.state.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('address_state')"
                    v-model="value.address_state"
                    @input="clearErrorMessages('address_state')"
                    :disabled="loadPostalCode"
                    :rules="rules.state"
                />
            </v-col>
        </v-row>

        <!-- Por enquanto está estático no modelo, ou seja, não precisa do campo -->
        <!-- <v-col cols="12" xs="12" sm="6" class="py-0">
            <cp-text-field
                ref="address_country_field"
                :label="$t('fieldset.AddressFieldset.country.label')"
                :placeholder="$t('fieldset.AddressFieldset.country.placeholder')"
                required type="text"
                :error-messages="getErrorMessages('address_country')"
                v-model="value.address_country"
                @input="clearErrorMessages('address_country')"
                :disabled="true"
            />
        </v-col> -->

    </v-row>
</template>

<script>
import FormMixin                 from '@/mixins/FormMixin'
import SimpleCustomer            from '@/models/SimpleCustomer'
import { length, address_state } from '@/utils/validation'
import { validatePostalCode }    from '@/utils/validation/postalCode'
import { 
    getZipCodeWebService,
    findZipCodeLink,
} from '@/shared/services/externalWebServices'

export default {
    name  : 'AddressFieldset',
    mixins: [ FormMixin(SimpleCustomer) ],
    data  : () => ({
        findZipCodeLink,
        loadPostalCode: false,
        rules: {
            number: [ length(0, 60) ],
            state : [ address_state ],
        },
    }),
    methods: {
        async getZipCode(postal_code) {
            this.loadPostalCode = true

            // [TODO] FID-1484 generalizar e implementar interface
            let response = await getZipCodeWebService(postal_code)
                .catch(this.preErrorHandler)

            this.loadPostalCode = false

            if (!response || this.$lodash.isEmpty(response))
                return

            this.value.address_state    = response.address_state
            this.value.address_district = response.address_district
            this.value.address_city     = response.address_city
            this.value.address_street   = response.address_street
        },
        async preErrorHandler(err) {
            let errType = err.message

            if (!errType)
                return

            const definedErrors = [
                'invalid_format',
                'postal_code_missing',
                'cep_not_exists',
            ]

            errType = definedErrors.includes(errType) ? errType : 'error'

            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`fieldset.AddressFieldset.postal_code.alerts.${errType}`), 'error', resolve)
            })

            this.value.address_postal_code = null
        },
    },
    watch: {
        "value.address_postal_code": function (value) {
            if (!value)
                return

            let postal_code        = value.replace(/[^\d]+/g,'').toString()
            let hasValidPostalCode = validatePostalCode(postal_code)

            if (hasValidPostalCode)
                this.getZipCode(postal_code)
        },
    },
}
</script>
