<template>
    <div>
        <LoadingModal ref="loading" />
        <CodeModal ref="code" @received="handleReceived" />
        <ConfirmationModal 
            ref="confirmation"
            @confirm="pushForgotPassword"
            :hasText="true"
            :hasSubtitle="false"
        />
        <ResetPasswordModal
            ref="reset_password"
            @confirm="submitResetPassword"
            @password-error="passwordError"
            @set-users-front="setUsersFront"
            @handle-logout="handlerForgotPassword"
        />
        <v-container class="pb-0">
            <v-row>
                <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" class="pb-1 px-3" :items="breadcrumbs">
                    <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
                </v-breadcrumbs>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" lg="6" sm="12">
                    <div class="section-title font-weight-bold primary_font--text">{{ $t('views.SettingsView.title') }}</div>
                </v-col>
                <v-col cols="12" md="6" lg="6" sm="12" v-if="!authUser.emailVerified">
                    <EmailConfirmation 
                        v-if="showEmailConfirmation"
                        @hide="showEmailConfirmation = false"
                        @email-sent="emailSent"
                        @email-error="emailError"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container class="pt-1">
            <v-row v-if="!loading">
                <v-col cols="12" class="pt-0">
                    <v-card outlined class="px-0">
                        <v-card-title class="primary--text subtitle-1">{{ $t('views.SettingsView.user_customer.title') }}</v-card-title>

                        <v-card-text >
                            <ProfileForm
                                ref="credentials_form"
                                v-model="userCredentials"
                                :isFormValid.sync="isCredentialsFormValid"
                                :useCNPJ="useCNPJ"
                                @submit="submit(userCredentials)"
                                @document-changed="changeDocument"
                                @triggerSubmit="triggerSubmitCredentials"
                                :loading="loading"
                            />
                        </v-card-text>

                        <!-- Desktop buttons -->
                        <v-card-actions  v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-end pb-8 px-4">
                            <v-btn
                                v-if="userCredentials.login_type == $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')"
                                class="primary px-4"
                                :class="isLightTheme ? 'primary_font--text': ''"
                                elevation="0"
                                @click="resetPassword"
                            >{{ $t('forms.ResetPassword.title')}}</v-btn>

                            <v-spacer></v-spacer>
                            <v-btn
                                text color="primary__font--text"
                                class="background px-4"
                                @click="goHome"
                            >{{ this.$t('views.SettingsView.navigation.cancel') }}</v-btn>

                            <v-btn
                                elevation="0" class="px-4" color="primary"
                                @click="triggerSubmitCredentials"
                                :disabled="!isCredentialsFormValid"
                                :loading="loading"
                                :class="isLightTheme ? 'primary_font--text': ''"
                            >{{ $t('actions.update') }}</v-btn>
                        </v-card-actions>

                        <!-- Mobile buttons -->
                        <v-card-text v-else>
                            <div class="mb-8">
                                <span v-if="userCredentials.login_type == $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')" class="primary--text clickable" @click="resetPassword">
                                    {{ $t('forms.ResetPassword.title')}}
                                </span>
                            </div>

                            <v-btn
                                block class="mb-2" color="primary" elevation="0"
                                @click="triggerSubmitCredentials"
                                :loading="loading"
                                :class="isLightTheme ? 'primary_font--text': ''"
                            >{{ $t('actions.update') }}</v-btn>

                            <v-btn
                                block text
                                color="blue"
                                @click="goHome"
                            >{{ this.$t('views.SettingsView.navigation.cancel') }}</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <div v-else class="text-center mt-5">
                {{ $t('globals.loading') }}
            </div>

            <div id="recaptcha"></div>

        </v-container>
    </div>
</template>

<script>
import ProfileForm         from '@/components/forms/ProfileForm'
import CodeModal           from '@/components/modals/CodeModal'
import ConfirmationModal   from '@/components/modals/ConfirmationModal'
import LoadingModal        from '@/components/modals/LoadingModal'
import SimpleCustomer      from '@/models/SimpleCustomer'
import RateColor           from '@/shared/mixins/RateColor'
import EmailConfirmation   from './EmailConfirmation'
import ResetPasswordModal  from '@/components/modals/settings/ResetPasswordModal'
import { mapGetters }      from 'vuex'


export default {
    name: 'CredentialsView',
    mixins: [ RateColor ],
    data: vm => ({
        userCredentials: new SimpleCustomer,
        isCredentialsFormValid: false,
        loading: false,
        editCustomer: true,
        useCNPJ: false,
        showEmailConfirmation: true,
        errType: null,
        breadcrumbs: [
            { text: vm.$t('drawer.home'), to: { name: 'home' } },
            { text: vm.$t('drawer.profile'), disabled: true },
        ],
    }),
    components: {
        ProfileForm,
        CodeModal,
        ConfirmationModal,
        EmailConfirmation,
        ResetPasswordModal,
        LoadingModal,
    },
    created() {
        if (!this.authUser)
            return

        this.userCredentials = this.authUser.clone()
        this.setUseCNPJ()
        this.setUsersFront()
        this.useCNPJ = this.userCredentials.use_cnpj
    },
    methods: {
        triggerSubmitCustomer() {
            this.$refs.customer_form.submit()
        },
        triggerSubmitCredentials() {
            this.$refs.credentials_form.submit()
        },
        async submit(user, method = 'update' ) {
            this.$refs.loading.open()
            this.loading = true

            user.formatToBack()

            let errType
            let response = await user[method]()
                .catch(err => {
                    this.error = true
                    this.loading = false

                    errType = err.response.data.type
                })

            if (!response || !response.data || response.status != 200 || errType) {
                const definedErrors = [
                    'update_error',
                    'update_customer_success',
                    'update_credentials_success',
                    'used_email',
                    'used_phone',
                    'used_document',
                    'wrong_password',
                ]
                errType = definedErrors.includes(errType) ? errType : 'default'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.SettingsView.alerts.${errType}`),'error', resolve)
                })
                this.$refs.loading.close()
                return false
            }

            this.$store.dispatch('auth/setUser', new SimpleCustomer(response.data.data))

            this.userCredentials = this.authUser.clone()

            if (method == 'update') {
                this.setUseCNPJ()
            }
            
            this.setUsersFront()

            this.userCredentials.password = ''

            this.loading = false
            this.$refs.loading.close()

            await this.$bus.$emit('message', this.$t('views.SettingsView.alerts.update_customer_success'), 'success')

        },
        async emailSent() {
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t('views.SettingsView.alerts.email_confirmation_sent', { email: this.userCredentials.email }), 'success', resolve, true)
            })
        },
        async emailError(errType) {
            let errTypeMessage = errType ? errType : 'default'

            this.showEmailConfirmation = false
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`views.SettingsView.alerts.${errTypeMessage}`), 'error', resolve)
            })
        },
        async customerUpdateSuccesss() {
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t('views.SettingsView.alerts.update_customer_success'), 'success', resolve)
            })
        },
        async passwordError() {
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`views.SettingsView.alerts.password_error`), 'error', resolve)
            })
        },
        setUsersFront() {
            this.userCredentials.formatToFront()
        },
        setUseCNPJ() {
            this.userCredentials.use_cnpj = this.userCredentials.determineUseCNPJ()
        },
        handleReceived(code) {
            this.$refs.code.close()
            this.userCredentials.code = code
            this.submit(this.userCredentials)
        },
        changeDocument(value) {
            this.useCNPJ = value
            this.userCredentials.use_cnpj = this.useCNPJ
        },
        goHome() {
            this.$router.push({ name: 'home'})
        },
        async resetPassword() {
            await this.$refs.reset_password.open()
        },
        async pushForgotPassword() {
            await this.$router.push({ name: 'logout' })
            this.$router.push({ name: 'auth.forgot_password',})
        },
        async handlerForgotPassword() {
            this.$refs.confirmation.showConfirmation(this.$t('forms.ResetPassword.confirmation_modal'))
        },
        async submitResetPassword(value){
            this.submit(value, 'updatePassword')
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
        }),
    },
}
</script>

<style scoped>
.theme--light.v-card.v-card--outlined {
    border-radius: .5rem;
    border: none !important;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>