<template>
    <BaseModal 
        ref="modal" :title="$t('modals.CodeModal.title')" :subtitle="$t('modals.CodeModal.subtitle')" :confirm="$t('modals.CodeModal.confirm')" :cancel="$t('modals.CodeModal.cancel')"
        max-width="400px" :customConfirm="true" @confirm="handleConfirm" :customCancel="true" @cancel="handleCancel" :persistent="true" :confirmDisabled="!isValid"
    >
        <template #text ref="test">
            <v-form @submit.prevent="handleConfirm" v-model="isValid">
                <v-text-field
                    class="pt-6"
                    v-model="code"
                    label="Código"
                    solo flat
                    :rules="rules.code" 
                ></v-text-field>
            </v-form>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'

export default {
    components: {
        BaseModal
    },
    data: vm => {
        return {
            code: '',
            isValid: false,
            rules: {
                code: [
                    val => (val || '').length == 6 || vm.$t('modals.CodeModal.errors.length')
                ]
            }
        }
    },
    methods: {
        open() {
            this.$emit('opening')
            this.$refs.modal.open()
            this.$emit('opened')
        },
        close() {
            this.$emit('closing')
            this.$refs.modal.close()
            this.$emit('closed')
        },
        handleConfirm() {
            if (!this.isValid) return

            this.$emit('received', this.code)
            this.code = ''
        },
        handleCancel() {
            this.$emit('canceled')
            this.code = ''
        },
    }
}
</script>