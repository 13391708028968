<template>
    <div class="card">
        <div v-if="this.authUser.login_type == $constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') &&
            !this.authUser.emailVerified &&
            !localEmailVerified"
            width="100%"
            class="d-flex align-center justify-space-between card-content"
        >   
            <div class="d-flex align-center justify-space-between card-content email-confirmation-content">
                <div class="mx-3 d-flex justify-center align-center">
                    <v-icon class="mr-3" color="primary">$cp_alert</v-icon>

                    <div class="caption primary--text">
                        {{ $t("views.SettingsView.send_email_confirmation_title") }}
                    </div>
                </div>
          
                <div class="d-flex flex-row justify-end mx-3 resend-mail">
                    <v-btn :class="isLightTheme ? 'indigo accent-4--text' : 'primary--text'"
                        class="mail-btn custom-btn"
                        depressed outlined tile
                        :loading="emailLoading"
                        @click="sendEmailConfirmation"
                    >{{ $t("views.SettingsView.navigation.send_email_confirmation") }}</v-btn>
                    <span class="mx-2 ml-4"><v-icon size="12" color="primary" @click="hideModal">fas fa-times</v-icon></span>
                </div>
            </div>

            <!-- Muda o comportamento para telas XS -->
            <div class="d-flex flex-column align-center justify-center card-content email-confirmation-content-xs py-2">
                <div class="mx-3 d-flex justify-center align-center">
                    <v-icon class="ml-n8 mr-2" color="primary">$cp_alert</v-icon>

                    <div class="caption primary--text">
                        {{ $t("views.SettingsView.send_email_confirmation_title") }}
                    </div>
                </div>
          
                <div class="d-flex flex-row justify-end resend-mail mt-2">
                    <v-btn :class="isLightTheme ? 'indigo accent-4--text' : 'primary--text'"
                        class="mail-btn custom-btn"
                        depressed outlined tile
                        :loading="emailLoading"
                        @click="sendEmailConfirmation"
                    >{{ $t("views.SettingsView.navigation.send_email_confirmation") }}</v-btn>
                    <span class="mx-2 ml-4"><v-icon size="12" color="primary" @click="hideModal">fas fa-times</v-icon></span>
                </div>
            </div>  
         
        </div>
    </div>
</template>

<script>
import RateColor from '@/shared/mixins/RateColor'
import { mapGetters }   from 'vuex'

export default {
    name: 'EmailConfirmation',
    mixins: [ RateColor ],
    data: vm => ({
        emailLoading       : false,
        localEmailVerified : false,
    }),
    methods: {
        async sendEmailConfirmation() {
            this.emailLoading = true

            let customer = this.authUser.clone()

            let errType = ''
            let response = await this.$axios({
                url: 'customer/email-confirmation',
                method: 'get',
                headers: {
                    'customer-email': customer.email,
                },
            }).catch(err => {
                this.error = true
                this.emailLoading = false

                if (err.response && err.response.data && err.response.data.message == 'email_confirmed') {
                    this.localEmailVerified = true
                    errType = 'email_confirmed'
                    return
                }

                errType = 'send_error'
            })

            if (!response || response.status != 204 || errType) {

                errType = errType ? errType : 'default'

                this.alertEmailError(errType)
                return
            }

            this.emailLoading = false

            this.alertEmailConfirmationSent()
        },
        hideModal() {
            this.$emit('hide');
        },
        alertEmailConfirmationSent() {
            this.$emit('email-sent')
        },
        alertEmailError(errType) {
            this.$emit('email-error', errType)
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
        }),    
    }
};
</script>

<style scoped>
.card {
    position: relative;
    z-index: 1;
    height: 40px;

    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;
}

.card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--v-primary-base);
    opacity: .1;
    z-index: -1;
}

.card-content {
    width: 100%;
}

.resend-mail {
    cursor: pointer;
    font-size: .875rem;
}

.mail-btn {
    background: transparent !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: auto;
    padding: 0;
}

.v-btn--outlined {
    border: none;
}

.v-btn { 
    letter-spacing: 0;
}

.custom-btn::before {
    color: transparent
}

.email-confirmation-content-xs {
    display: none !important;
}

@media (max-width: 445px) {
    .email-confirmation-content {
        display: none !important;
    }

    .email-confirmation-content-xs {
        display: flex !important;
    }

    .card {
        height: auto;
    }

}

</style>